import { SelectableCard } from '@uag/react-core';
import { clsx } from 'clsx';

type Props = {
    text?: string;
    image?: string;
    value: string;
    className?: string;
    imageClassName?: string;
};
export const Card = ({ text, image, className, imageClassName, value }: Props) => {
    return (
        <SelectableCard
            className={clsx(className, 'flex h-80 w-full flex-col items-center justify-evenly')}
            value={value}
        >
            <div className="content-center">
                <img alt="card" className={imageClassName} src={image} />
            </div>
            <div className="text-xl">{text}</div>
        </SelectableCard>
    );
};
