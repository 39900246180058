import { ParseKeys, createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'translations/en.json';

const getVariationOfAOrAn = (value: string, capitalize: boolean) => {
    const letters = ['A', 'E', 'I', 'O', 'U', 'H', 'a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = value.substring(0, 1);
    if (
        letters.find(function (l) {
            return firstLetter === l;
        })
    ) {
        return capitalize ? 'An' : 'an';
    }
    return capitalize ? 'A' : 'a';
};

export const defaultNS = 'translations';
export const resources = {
    'en-US': { Name: 'English (US)', translations: en },
    'en-GB': { Name: 'English (UK)', translations: en }
};

export const sharedInstance = createInstance();

sharedInstance.use(initReactI18next).init({
    fallbackLng: ['en-US'],
    detection: {
        order: ['cookie', 'localStorage', 'navigator'],
        caches: ['cookie', 'localStorage']
    },
    defaultNS,
    resources,
    returnNull: false,
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (format === 'uppercase') {
                return value.toUpperCase();
            }
            if (format === 'lowercase') {
                return value.toLowerCase();
            }
            if (format === 'en-handle-an') {
                return !lng || lng === 'en' ? getVariationOfAOrAn(value, false) : '';
            }
            if (format === 'en-handle-an-capitalized') {
                return !lng || lng === 'en' ? getVariationOfAOrAn(value, true) : '';
            }
            if (format === 'en-handle-lowercase') {
                return !lng || lng === 'en' ? value.toLowerCase() : value;
            }
            return value;
        }
    }
});

declare module 'i18next' {
    interface CustomTypeOptions {
        defaultNS: typeof defaultNS;
        resources: (typeof resources)['en-US'];
        returnNull: false;
    }
}

export type TranslationKeys = ParseKeys<'translations'>;
