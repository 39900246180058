/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetSignatureLinkResponse,
  ProblemDetails,
  SigningCompletedCallbackParams,
  SigningCompletedModel,
  VerifySignatureModel
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a document without signature
 */
export const getSignatureLink = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetSignatureLinkResponse>(
      {url: `/requests/${id}/signature`, method: 'GET', signal
    },
      options);
    }
  

export const getGetSignatureLinkQueryKey = (id: string,) => {
    return [`/requests/${id}/signature`] as const;
    }

    
export const getGetSignatureLinkQueryOptions = <TData = Awaited<ReturnType<typeof getSignatureLink>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignatureLink>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSignatureLinkQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSignatureLink>>> = ({ signal }) => getSignatureLink(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSignatureLink>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSignatureLinkQueryResult = NonNullable<Awaited<ReturnType<typeof getSignatureLink>>>
export type GetSignatureLinkQueryError = ErrorType<ProblemDetails>


export function useGetSignatureLink<TData = Awaited<ReturnType<typeof getSignatureLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignatureLink>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSignatureLink>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetSignatureLink<TData = Awaited<ReturnType<typeof getSignatureLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignatureLink>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSignatureLink>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetSignatureLink<TData = Awaited<ReturnType<typeof getSignatureLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignatureLink>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetSignatureLink<TData = Awaited<ReturnType<typeof getSignatureLink>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignatureLink>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetSignatureLinkQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Callback url for docusign service
 */
export const signingCompletedCallback = (
    id: string,
    signingCompletedModel: SigningCompletedModel,
    params: SigningCompletedCallbackParams,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/signature/callback`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signingCompletedModel,
        params
    },
      options);
    }
  


export const getSigningCompletedCallbackMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signingCompletedCallback>>, TError,{id: string;data: SigningCompletedModel;params: SigningCompletedCallbackParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof signingCompletedCallback>>, TError,{id: string;data: SigningCompletedModel;params: SigningCompletedCallbackParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof signingCompletedCallback>>, {id: string;data: SigningCompletedModel;params: SigningCompletedCallbackParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  signingCompletedCallback(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SigningCompletedCallbackMutationResult = NonNullable<Awaited<ReturnType<typeof signingCompletedCallback>>>
    export type SigningCompletedCallbackMutationBody = SigningCompletedModel
    export type SigningCompletedCallbackMutationError = ErrorType<ProblemDetails>

    export const useSigningCompletedCallback = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof signingCompletedCallback>>, TError,{id: string;data: SigningCompletedModel;params: SigningCompletedCallbackParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof signingCompletedCallback>>,
        TError,
        {id: string;data: SigningCompletedModel;params: SigningCompletedCallbackParams},
        TContext
      > => {

      const mutationOptions = getSigningCompletedCallbackMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get all signed contracts from an request
 */
export const getSignedContracts = (
    id: string,
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<Blob>(
      {url: `/requests/${id}/signature/contracts`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetSignedContractsQueryKey = (id: string,) => {
    return [`/requests/${id}/signature/contracts`] as const;
    }

    
export const getGetSignedContractsQueryOptions = <TData = Awaited<ReturnType<typeof getSignedContracts>>, TError = ErrorType<ProblemDetails>>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContracts>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSignedContractsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSignedContracts>>> = ({ signal }) => getSignedContracts(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSignedContracts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSignedContractsQueryResult = NonNullable<Awaited<ReturnType<typeof getSignedContracts>>>
export type GetSignedContractsQueryError = ErrorType<ProblemDetails>


export function useGetSignedContracts<TData = Awaited<ReturnType<typeof getSignedContracts>>, TError = ErrorType<ProblemDetails>>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContracts>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSignedContracts>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetSignedContracts<TData = Awaited<ReturnType<typeof getSignedContracts>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContracts>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSignedContracts>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetSignedContracts<TData = Awaited<ReturnType<typeof getSignedContracts>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContracts>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetSignedContracts<TData = Awaited<ReturnType<typeof getSignedContracts>>, TError = ErrorType<ProblemDetails>>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSignedContracts>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetSignedContractsQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Verify the signature
 */
export const verifySignature = (
    id: string,
    verifySignatureModel: VerifySignatureModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/requests/${id}/signature/verify`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: verifySignatureModel
    },
      options);
    }
  


export const getVerifySignatureMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifySignature>>, TError,{id: string;data: VerifySignatureModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof verifySignature>>, TError,{id: string;data: VerifySignatureModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifySignature>>, {id: string;data: VerifySignatureModel}> = (props) => {
          const {id,data} = props ?? {};

          return  verifySignature(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type VerifySignatureMutationResult = NonNullable<Awaited<ReturnType<typeof verifySignature>>>
    export type VerifySignatureMutationBody = VerifySignatureModel
    export type VerifySignatureMutationError = ErrorType<ProblemDetails>

    export const useVerifySignature = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifySignature>>, TError,{id: string;data: VerifySignatureModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof verifySignature>>,
        TError,
        {id: string;data: VerifySignatureModel},
        TContext
      > => {

      const mutationOptions = getVerifySignatureMutationOptions(options);

      return useMutation(mutationOptions);
    }
    