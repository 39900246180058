import { clsx } from 'clsx';
import { ReactNode } from 'react';

type InformationProps = {
    children: ReactNode;
    className?: string;
};

export const Information = ({ children, className }: InformationProps) => (
    <div className={clsx('text-sm flex flex-col gap-4 bg-background-gray-ultra-light p-4', className)}>{children}</div>
);

type InformationSectionProps = {
    title: string;
    children: ReactNode;
    className?: string;
};

export const InformationSection = ({ title, children, className }: InformationSectionProps) => (
    <div className={className}>
        <h6 className="text-sm-bold">{title}</h6>
        {children}
    </div>
);
