/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */

export type StepProgress = typeof StepProgress[keyof typeof StepProgress];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepProgress = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Aborted: 'Aborted',
  Declined: 'Declined',
  Completed: 'Completed',
} as const;
