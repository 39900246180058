import { InputWrapper, MantineThemeOverride } from '@mantine/core';
import { classNames } from '@uag/react-core';

export const getCustomThemeOverrides = (): MantineThemeOverride => ({
    components: {
        InputBase: {
            defaultProps: {
                unstyled: true
            },
            classNames: classNames.textField.default
        },
        InputWrapper: InputWrapper.extend({
            defaultProps: {
                unstyled: true
            },
            classNames: {
                required: 'text-text-alert -ml-1'
            }
        })
    }
});
