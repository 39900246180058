import { useOidcUser } from '@axa-fr/react-oidc';
import { Badge, Button } from '@mantine/core';
import { RequestStateType } from '@shared/api/models';
import { useGetRequests } from '@shared/api/queries/requests/requests';
import { Icon, LoadingAnimation, SelectableCard, variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'timeago-react';

const getBadgeVariant = (state: RequestStateType): string => {
    switch (state) {
        case RequestStateType.DataVerification:
        case RequestStateType.SignatureVerification:
            return variants.badge.warning;
        case RequestStateType.CoreSystemActivation:
        case RequestStateType.ServiceActivation:
            return variants.badge.primary;
        case RequestStateType.Invited:
        case RequestStateType.AdditionalDataInput:
            return variants.badge.secondary;
        case RequestStateType.Completed:
            return variants.badge.success;
        case RequestStateType.Declined:
        case RequestStateType.Aborted:
            return variants.badge.alert;
        default:
            return '';
    }
};

export const ManageRequests = () => {
    const { t } = useTranslation();
    const [requestId, setRequestId] = useState('');
    const navigate = useNavigate();
    const { data: requestsResponse, isLoading: isRequestsLoading } = useGetRequests();
    const { oidcUser } = useOidcUser();
    const requests = requestsResponse?.data;

    if (isRequestsLoading) {
        return <LoadingAnimation />;
    }

    const handleContinue = () => {
        if (requestId === 'newRequest') {
            return navigate('/selectCustomer');
        }
        navigate(`/request/${requestId}`);
    };

    return (
        <div className="flex flex-col gap-4">
            <h3 className="text-3xl">{t('customerType')}</h3>
            <SelectableCard.Group className="my-6 gap-6" value={requestId} wrap="wrap" onChange={setRequestId}>
                {requests?.map((request) => (
                    <SelectableCard
                        key={request.id}
                        className="flex h-52 w-64 flex-col items-center justify-center gap-4"
                        value={request.id}
                    >
                        <div className="flex h-16 w-16 items-center justify-center rounded-full border-2 border-border-default text-icon-default">
                            <Icon icon="domain" size="xl" />
                        </div>
                        <div className="gap flex flex-col items-center gap-1">
                            {request.companyData?.companyName && (
                                <h6 className="text-base">{request.companyData?.companyName}</h6>
                            )}
                            <div className="text-xs flex items-center gap-1 text-text-ultra-light">
                                {request.currentState && (
                                    <Badge variant={getBadgeVariant(request.currentState)}>
                                        {t(request.currentState)}
                                    </Badge>
                                )}
                                {request.currentState && request.modifiedDate && <span>|</span>}
                                {request.modifiedDate && (
                                    <span>
                                        <TimeAgo datetime={request.modifiedDate} locale={oidcUser.locale} />
                                    </span>
                                )}
                            </div>
                        </div>
                        <span className="text-sm text-text-ultra-light">{`${t('createdOn')} ${new Date(request.createdDate).toLocaleDateString(oidcUser.locale)}`}</span>
                    </SelectableCard>
                ))}
                <SelectableCard
                    className="flex h-52 w-64 flex-col items-center justify-center gap-4"
                    value="newRequest"
                >
                    <div className="flex h-16 w-16 items-center justify-center rounded-full border-2 border-border-default text-icon-default">
                        <Icon icon="add" size="xl" />
                    </div>
                    <h6 className="text-base">{t('add')}</h6>
                </SelectableCard>
            </SelectableCard.Group>
            <Button className="ml-auto" disabled={!requestId} onClick={handleContinue}>
                {t('continue')}
            </Button>
        </div>
    );
};
