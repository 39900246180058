import axios, { AxiosError, RawAxiosRequestConfig } from 'axios';

const idsApiClient = axios.create({
    baseURL: import.meta.env.VITE_APP_NOTIFICATIONS_API_URL
});

// add a second `options` argument here if you want to pass extra options to each generated query
// prettier-ignore
export const customMutator = <T,>(config: RawAxiosRequestConfig, options?: RawAxiosRequestConfig): Promise<T> => {
    
    const promise = idsApiClient({
        ...config,
        ...options,
    }).then(({ data }) => data);

    return promise;
};

export type ErrorType<Error> = AxiosError<Error>;
