import { useOidcContext } from '@uag/react-core';
import { createContext, ReactNode } from 'react';

import { applicationInsights, applicationInsightsReactPlugin } from './applicationInsights';

const AppInsightsContext = createContext(applicationInsightsReactPlugin);

const AppInsightsContextProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useOidcContext();

    if (user?.sub) {
        applicationInsights.setAuthenticatedUserContext(user?.sub, user?.sub);
    }

    return <AppInsightsContext.Provider value={applicationInsightsReactPlugin}>{children}</AppInsightsContext.Provider>;
};

export { AppInsightsContext, AppInsightsContextProvider };
