import { useGetRequestById } from '@shared/api/queries/requests/requests';
import { useGetSteps } from '@shared/api/queries/steps/steps';
import { ErrorView, Layout, LoadingAnimation, Timeline, useElementSize } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { orderBy } from 'shared/utils';
import { Step } from './Step';

export const Request = () => {
    const { t } = useTranslation();
    const { requestId = '' } = useParams();
    const { ref, width } = useElementSize();
    const layout = width < 800 && ref ? Layout.OneColumn : Layout.TwoColumns;

    // TODO: Id + Token => useAcceptInvitation()
    const { data: steps, isLoading: areStepsLoading, isError } = useGetSteps(requestId);
    const { data: request, isLoading: isRequestLoading } = useGetRequestById(requestId);

    if (isError) {
        return <ErrorView description={t('errorDescription')} />;
    }

    return (
        <>
            <div className="mb-7">
                <h1 className="text-5xl text-balance p-2 text-text-accent md:text-6xl md:p-0">{t('welcomeToDoka')}</h1>
                <p className="text-3xl p-2 font-normal text-text-default md:p-0">{t('niceToHaveYou')}</p>
            </div>
            <div ref={ref} className="m-auto max-w-[1564px] pb-16">
                <Timeline className="m-2" layout={layout}>
                    {(areStepsLoading || isRequestLoading) && <LoadingAnimation />}
                    {steps &&
                        steps.data &&
                        request &&
                        request.data &&
                        orderBy(steps.data, ['order']).map((step) => (
                            <Step key={step.order} request={request.data!} step={step} />
                        ))}
                </Timeline>
            </div>
        </>
    );
};
