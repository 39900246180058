import { OnboardingRequestDetailModel, StepModel, StepType } from '@shared/api/models';
import { Timeline } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

export const StepContact = ({ step, request }: { step: StepModel; request: OnboardingRequestDetailModel }) => {
    const { t } = useTranslation('translations');
    if (!step.user) {
        return undefined;
    }

    if (step.type === StepType.Verification && request.currentState === 'Declined') {
        return undefined;
    }

    if (
        step.type !== StepType.VerificationResult &&
        !(step.type === StepType.InviteFromSales && step.progress === 'Completed')
    ) {
        return undefined;
    }

    return (
        <Timeline.Contact
            email={step.user?.email ?? ''}
            headline={t('yourCustomerAdvisor')}
            image={step.user.profilePictureUri ?? undefined}
            name={step.user?.displayName}
            phone={step.user.phoneNumber ?? undefined}
        />
    );
};
