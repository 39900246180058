import { LoadingAnimation, toastNotifications, getExceptionDetails } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { welcomeApiClient } from 'api/customMutator';
import { OnboardingRequestModel } from 'api/models';
import { getDownloadAttachmentContentQueryKey, useGetAttachments } from 'api/queries/attachments/attachments';
import { RequestAttachment } from './RequestAttachment';

type Props = {
    request: OnboardingRequestModel;
};

export const RequestAttachments = ({ request }: Props) => {
    const { data, isPending } = useGetAttachments(request.id ?? '');
    const attachments = data?.data ?? [];
    const { t } = useTranslation();

    const handleAttachmentDownload = async (attachmentId: string, attachmentName: string) => {
        try {
            const blob = await welcomeApiClient.get(getDownloadAttachmentContentQueryKey(request.id, attachmentId).join(), { responseType: 'blob' });
            const url = window.URL.createObjectURL(blob.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = attachmentName;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove(); // afterwards we remove the element again
        } catch (err) {
            toastNotifications.error({ title: t('errorDownloadingAttachment'), message: getExceptionDetails(err)?.message });
        }
    };

    return (
        <div>
            <div>{t('documents')}</div>

            {isPending && <LoadingAnimation />}
            <div className="grid grid-cols-2 gap-base">
                {attachments &&
                    attachments.map((attachment) => (
                        <RequestAttachment
                            key={attachment.id}
                            attachmentType={attachment.attachmentType}
                            fileName={attachment.fileName}
                            fileSizeBytes={attachment.fileSizeBytes}
                            id={attachment.id}
                            onClick={() => handleAttachmentDownload(attachment.id, attachment.fileName!)}
                        />
                    ))}
            </div>
        </div>
    );
};
