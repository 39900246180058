import {
    AttachmentType,
    CreateInvitationNewModel,
    CreateOrUpdateCompanyDataModel,
    OnboardingRequestDetailModel,
    UpdateOnboardingRequestModel
} from 'api/models';
import { CompanyBaseDataSchemaType, InvitationSchemaType, RequestSchemaType } from './companyFormSchema';

export const getInitialRequestValues = (request: OnboardingRequestDetailModel, defaultMail: string = ''): RequestSchemaType => {
    const company = request?.companyData;
    return {
        companyData: {
            companyName: company?.companyName ?? '',
            additionalCompanyName: company?.additionalCompanyName ?? '',
            industrialSector: company?.industrialSectorIdentifier ?? '',
            country: company?.mainAddress?.countryConfiguration?.id ?? '',
            street: company?.mainAddress?.street ?? '',
            zipCode: company?.mainAddress?.zipCode ?? '',
            city: company?.mainAddress?.city ?? '',
            uidNumber: company?.taxNr ?? '',
            taxNumber: company?.taxNr ?? '',
            generalOfficeMail: company?.email ?? '',
            phoneNumber: company?.phone ?? '',
            mobileNumber: company?.mobilePhone ?? ''
        },
        technicalAdministrator: request?.administrators?.[0]?.model?.email ?? defaultMail,
        contractSignatory: request?.signers?.[0]?.model?.email ?? defaultMail,
        businessLicence:
            request?.attachments?.find((attachment) => attachment.exists && attachment.attachmentType === AttachmentType.BusinessLicense)?.id ?? ''
    };
};

export const companyToRequest = (values: RequestSchemaType): UpdateOnboardingRequestModel => ({
    administrators: values.technicalAdministrator ? [{ model: { email: values.technicalAdministrator } }] : null,
    signers: values.contractSignatory ? [{ model: { email: values.contractSignatory } }] : null,
    companyData: getCompanyData(values.companyData)
});

const getCompanyData = (companyData: CompanyBaseDataSchemaType): CreateOrUpdateCompanyDataModel => ({
    companyName: companyData.companyName,
    additionalCompanyName: companyData.additionalCompanyName,
    industrialSectorIdentifier: companyData.industrialSector,
    mainAddress: {
        countryConfigurationId: !companyData.country ? null : companyData.country,
        street: companyData.street,
        zipCode: companyData.zipCode,
        city: companyData.city
    },
    taxNr: companyData.uidNumber,
    email: companyData.generalOfficeMail,
    phone: companyData.phoneNumber,
    mobilePhone: companyData.mobileNumber,
    electronicInvoiceEmail: companyData.generalOfficeMail,
    useElectronicInvoice: true
});

export const companyToInvite = (values: InvitationSchemaType): CreateInvitationNewModel => ({
    invitee: { model: { email: values.companyRegistrar } },
    companyData: getCompanyData(values.companyData)
});
