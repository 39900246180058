import { OnboardingRequestModel, StepModel, StepProgress, StepType } from '@shared/api/models';
import { ProcessInformation, Timeline, TimelineItemState } from '@uag/react-core';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

import { StepAction } from './StepAction';
import { StepContact } from './StepContact';

type Props = {
    step: StepModel;
    request: OnboardingRequestModel;
};

const getProcessText = (step: StepModel) => {
    if (step.progress === StepProgress.InProgress) {
        return t('lastEditedOn');
    }
    if (step.progress === StepProgress.Completed) {
        return t('submittedOn');
    }
    return undefined;
};

const getProcessInformation = (step: StepModel, request: OnboardingRequestModel) => {
    if (
        step.progress === StepProgress.Pending ||
        step.type === StepType.VerificationResult ||
        !step.user ||
        !step.completedTimeStamp ||
        (step.type === StepType.Verification && request.currentState === 'Declined')
    ) {
        return undefined;
    }

    return {
        name: step.user.displayName,
        email: step.user.email ?? undefined,
        image: step.user.profilePictureUri ?? undefined,
        processedDate: new Date(step.completedTimeStamp),
        processedText: getProcessText(step)
    } satisfies ProcessInformation;
};

const getState = (step: StepModel, request: OnboardingRequestModel) => {
    switch (step.progress) {
        case StepProgress.Completed: {
            if (step.type === StepType.Verification && request.currentState === 'Declined') {
                return TimelineItemState.Aborted;
            }
            return TimelineItemState.Done;
        }
        case StepProgress.InProgress:
            return TimelineItemState.Active;
        case StepProgress.Declined:
            return TimelineItemState.Aborted;
        default:
            if (step.type === StepType.VerificationResult) {
                return TimelineItemState.Pending;
            }
            return TimelineItemState.Pending;
    }
};

export const Step = ({ step, request }: Props) => {
    const { t } = useTranslation('translations', { keyPrefix: `steps.${step.type}.${step.progress}` });

    const processInformation = getProcessInformation(step, request);
    const state = getState(step, request);
    const title = t('title');
    const description = t('description');

    return (
        <Timeline.Item
            position={step.responsibleParty === 'Company' ? 'Right' : 'Left'}
            processInformation={state === 'Aborted' ? undefined : processInformation}
            state={state}
            title={title}
        >
            <StepContact request={request} step={step} />
            {description}
            {step.actions &&
                step.actions.map((stepAction, index) => (
                    <StepAction key={index} stepAction={stepAction} stepProgress={step.progress} />
                ))}
        </Timeline.Item>
    );
};
