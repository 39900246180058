import { BaseLayout, UserContextMenuItem, Notifications } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { useGetInboxToken } from 'notificationsApi/queries/inbox/inbox';
import { knockConfiguration } from 'shared/utils';

export const OnboardingFrame = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: inboxToken } = useGetInboxToken();

    return (
        <BaseLayout
            className="bg-background-gray-ultra-ultra-light"
            innerClassName="h-full p-6 mx-auto max-w-[1680px]"
            versionNumber={APP_VERSION}
        >
            <UserContextMenuItem
                icon="domain"
                title={t('myRegistrations')}
                onClick={() => navigate('/manageRequests')}
            />
            {inboxToken?.data && (
                <Notifications
                    userId={inboxToken.data.userIdentifier}
                    userToken={inboxToken.data.token}
                    {...knockConfiguration}
                />
            )}
            <Outlet />
        </BaseLayout>
    );
};
