import RandExp from 'randexp';

type CountryRuleConstructorProps = {
    id: string;
    zipCodeRegex: RegExp;
    taxNumberRegex: RegExp;
};

export class CountryRule {
    id: string;
    zipCodeRegex: RegExp;
    zipCodeSample: string;
    taxNumberRegex: RegExp;
    taxNumberSample: string;

    constructor({ id, zipCodeRegex, taxNumberRegex }: CountryRuleConstructorProps) {
        this.id = id;
        this.zipCodeRegex = zipCodeRegex;
        this.zipCodeSample = new RandExp(zipCodeRegex).gen();
        this.taxNumberRegex = taxNumberRegex;
        this.taxNumberSample = new RandExp(taxNumberRegex).gen();
    }

    static All() {
        return countryRules;
    }
}

const countryRules = [
    new CountryRule({
        // Austria
        id: '841eb7fb-6508-4577-a974-09e0153f859e',
        zipCodeRegex: /^[1-9]\d{3}$/,
        taxNumberRegex: /^ATU\d{8}$/
    }),
    new CountryRule({
        // Germany
        id: '102f566d-25d4-494a-a979-e05528023b8d',
        zipCodeRegex: /^[1-9]\d{4}$/,
        taxNumberRegex: /^DE\d{9}$/
    }),
    new CountryRule({
        // Switzerland
        id: '452552f4-a994-4525-a0c0-ef6bff28696c',
        zipCodeRegex: /^[1-9]\d{3}$/,
        taxNumberRegex: /^CHE\d{9}$/
    }),
    new CountryRule({
        // Italy
        id: '6C283A79-1DC3-4EE5-9F2D-DB97FDDBFDE6',
        zipCodeRegex: /^\d{5}$/,
        taxNumberRegex: /^IT\d{11}$/
    }),
    new CountryRule({
        // USA
        id: 'F0914454-DF6B-4135-B328-1F6F778500BC',
        zipCodeRegex: /^\d{5}(?:[-\s]\d{4})?$/,
        taxNumberRegex: /^\d{15}$/
    })
];
