export const orderBy = <T,>(arr: T[], props: (keyof T)[], orders?: ('asc' | 'desc')[]) =>
    [...arr].sort((a, b) =>
        props.reduce((acc, prop, i) => {
            if (acc === 0) {
                const [p1, p2] = orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]];
                acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
            }
            return acc;
        }, 0)
    );

export const knockConfiguration = {
    apiKey: import.meta.env.VITE_KNOCK_PUBLIC_API_KEY,
    feedId: import.meta.env.VITE_KNOCK_FEED_CHANNEL_ID
};
